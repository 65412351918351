import React, { useEffect, useState } from 'react'
import { Button, Card, Container, Row } from 'react-bootstrap'
import { getJobApplications } from '../services/jobs.ts'

const ViewJobApplicants = () => {

    const [jobApplicants, setJobApplicants] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getJobApplications().then((res) => {
            if (res.status === 200) {
                setJobApplicants(res.entries);
            } else {
                console.log('Failed to fetch job applicants');
            }
        }).catch((err) => {
            alert('Failed to fetch job applicants');
        }).finally(() => {
            setLoading(false);
        });
    }, [])

    const Base64ToDownload = ({ base64String, filename }) => {
        try {
            if (!base64String) {
                alert('No file uploaded');
                return;
            }
            console.log('base64String', typeof base64String);
            const byteString = atob(base64String?.split(',')[1]);
            const mimeString = base64String?.split(',')[0].split(':')[1].split(';')[0];
            const blob = new Blob([byteString], { type: mimeString });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = filename;
            link.click();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Container>
            <h1 style={{
                textAlign: 'center',
                margin: '20px'
            }}>View Job Applicants</h1>
            <Row>
                {loading ? 'Loading...' : jobApplicants.map((applicant, index) => (
                    <Card style={{
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                        borderRadius: '10px',
                        margin: '10px',
                        padding: '10px',
                        // width: '300px',
                        flex: '1',
                        display: 'flex',

                    }}>
                        <Card.Body>
                            <div key={index}>
                                <h3>{applicant["textarea-1"].value}</h3>
                                <p className='text-mute'>Applied on {applicant["date-1"].value}</p>
                                <p>Name : {applicant["name-1"].value}</p>
                                <p>Phone : {applicant["phone-1"].value}</p>
                                <p>Email : {applicant["email-1"].value}</p>
                                <Button variant="primary" onClick={() => { Base64ToDownload({ base64String: applicant["upload-1"].value, filename: `${applicant["name-1"].value} (${applicant["textarea-1"].value})` }) }}>Download DOC</Button>
                            </div>
                        </Card.Body>
                    </Card>
                ))}
            </Row>
        </Container>
    )
}

export default ViewJobApplicants
