import { setRSearchCourses, setFreeCourses, setRCategory, setRCourses, setRTrandingCourses, setRCourseDetails, setRCourseLoading, setRCourseTrandinglLoading, setRCourseDetailLoading, setRTags } from "../app/reducers/courseReducer";
import { setRLoginLoading, setRUserData, setRIsLogin, setRUserID } from "../app/reducers/userReducer";
import { setRUserOrders, setRUserOrderProducts, setRCartData, setRCartCookies, } from "../app/reducers/cartReducer";
import { getBlogById, getBlogCategories, getBlogs, setLoading } from "../app/reducers/Blogs";


let baseUrl = "";


export const fetchCountryInfo = async () => {
    const baseUrl1 = localStorage.getItem('baseUrl');

    if (!baseUrl1 || baseUrl1 === 'null') {
        try {
            const response = await fetch('https://ipinfo.io/?token=270824413ae382');
            const data = await response.json();
            const country = data.country || 'Expired';
            localStorage.setItem('country', country);

            let baseUrl = '';
            if (country && country === 'IN') {
                baseUrl = 'https://learnqacdstg.wpenginepowered.com';
            } else {
                baseUrl = 'https://learn.qacademy.tech';
            }



            localStorage.setItem('baseUrl', baseUrl);
            return baseUrl;
        } catch (error) {
            console.error('Error fetching country information:', error);
            // Handle error scenario - set a default base URL or perform necessary actions
            // Example:
            // localStorage.setItem('baseUrl', 'https://learn.qacademy.tech');
            // return 'https://learn.qacademy.tech';
        }
    } else {
        return baseUrl1;
    }
};



// export const getCourseCategory = async (fn) => {
//     try {

//         const response = await fetch(baseUrl+'/wp-json/wp/v2/course-category');
//         const jsonData = await response.json();
//         let resData = [];
//         if (jsonData) {
//             const data = jsonData.map((data) => {
//                 const { id, name } = data;
//                 resData = [...resData, { id: id, name: name }]
//             });
//             fn(setRCategory(resData));
//         }

//     } catch (error) {
//         console.error('Error:', error);
//     }

// }

export const getCourseCategory = async (fn) => {
    try {

        const baseUrl = await fetchCountryInfo();
        //const response = await fetch(baseUrl+'/wp-json/wp/v2/course-category');
        // current time stamp
        const currentTime = new Date().getTime();
        const response = await fetch(baseUrl + '/wp-json/lmscourses/v2/category-tag-courses?time=' + currentTime);
        const jsonData = await response.json();
        // TODO: it giving the error while response convert to json not able to convert the response to json
        let resData = [];
        if (jsonData) {
            await jsonData.map((data) => {
                //const { id, name } = data;
                resData = [...resData, data]
            });
            fn(setRCategory(resData));
        }

    } catch (error) {
        console.error('Error:', error);
    }

}

export const getCourseTags = async (fn) => {
    try {
        const baseUrl = await fetchCountryInfo();
        const response = await fetch(baseUrl + '/wp-json/lmscourses/v2/tag-category-courses');
        const jsonData = await response.json();
        let resData = [];
        if (jsonData) {
            const data = jsonData.map((data) => {
                // const { id, name } = data;
                resData = [...resData, data]
            });
            fn(setRTags(resData));
        }

    } catch (error) {
        console.error('Error:', error);
    }

}

export const getCoursesByCatID = async (id, name, fn) => {
    try {
        if (id) {
            const baseUrl = await fetchCountryInfo();
            const response = await fetch(`${baseUrl}/wp-json/wp/v2/courses?course-category=${id}`);
            const jsonData = await response.json();
            let resData = [];
            if (jsonData) {
                const data = jsonData.map((data) => {
                    let img = null;
                    fetchImage(data?.featured_media).then((r) => {
                        fetchCourseDetails(data?.id).then((detail) => {
                            resData = [...resData,
                            {
                                id: data?.id,
                                categoryId: id,
                                categoryName: name,
                                name: data?.title?.rendered,
                                sale_price: data?.sale_price,
                                price: data?.price,
                                regular_price: data?.regular_price,
                                image: r,
                                level: detail?.course_level[0],
                                duration: detail?.course_duration[0],
                                video_count: detail?.topics.length,
                            }
                            ]
                            fn(setRCourses(resData));

                        });

                    });

                });
            }
        }

    } catch (error) {
        console.error('Error:', error);
    }
}

export const fetchCourseDetails = async (id) => {
    try {
        if (id) {
            const baseUrl = await fetchCountryInfo();
            const response = await fetch(`${baseUrl}/wp-json/tutor/v1/course-detail/${id}`);
            const jsonData = await response.json();
            return jsonData?.data;
        }
    } catch (error) {
        console.error('Error:', error);
    }
};
export const fetchImage = async (id) => {
    try {
        if (id) {
            const baseUrl = await fetchCountryInfo();
            const response = await fetch(`${baseUrl}/wp-json/wp/v2/media/${id}`);
            const jsonData = await response.json();
            return jsonData?.guid?.rendered;
        }
    } catch (error) {
        console.error('Error:', error);
    }
};

export const CourseDetail = async (course_id = 0, course_name = '', fn) => {
    try {
        fn(setRCourseDetailLoading(true))
        fn(setRCourseDetails([]))
        const baseUrl = await fetchCountryInfo();
        const response = await fetch(`${baseUrl}/wp-json/lmscourses/v2/courses-by-slug?course_name=${course_name}`);
        const jsonData = await response.json();
        let resData = [];
        if (jsonData) {
            const data = jsonData.map((data) => {
                let img = null;
                // fetchImage(data?.featured_media).then((r) => {

                //     fetchCourseDetails(data?.id).then((detail) => {

                if (['free', 'paid','Paid','Free','PAID','FREE'].includes(data.course_price_type)) {
                    resData = [...resData,
                    {
                        id: data?.ID,
                        categoryId: data?.categories[0]?.term_id,
                        categoryName: data?.categories[0]?.name,
                        slug: data?.post_name,
                        categoryNames: data?.category_names,
                        description: data?.post_content,
                        name: data?.post_title,
                        image: data?.images[0]?.guid,
                        images: data?.images,
                        level: data?.course_level,
                        duration: data?.course_duration,
                        video_count: data?.detail?.topics.length,
                        video: data?.video,
                        sale_price: data?.sale_price,
                        price: data?.price,
                        product_id: data?.product_id,
                        regular_price: data?.regular_price,
                        course_requirements: data?.course_requirements,
                        course_material_includes: data?.course_material_includes,
                        course_settings: data?.course_settings,
                        course_target_audience: data?.course_target_audience,
                        course_price_type: data?.course_price_type,
                        topics: data?.topics,
                        course_benefits: data?.course_benefits,
                        tags: data?.tags
                    }
                    ]
                    fn(setRCourseDetails(resData));
                }
            });
        }
        fn(setRCourseDetailLoading(false))
        //  }

    } catch (error) {
        console.error('Error:', error);
    }
};

export const getTrandingCourses = async (course_id = 0, category_id = 0, tag_id = 0, fn) => {
    try {
        //   if (id) {
        // const response = await fetch(`${baseUrl}/wp-json/wp/v2/courses?course-category=${id}`);
        // const response = await fetch(`${baseUrl}/wp-json/wc/v3/products?consumer_key=ck_764c80dfa5a5e48e54c934b2391442b2192b6ebc&consumer_secret=cs_4e61cd2ad57a84cd75d1f2a32c4c5b84e5b9708b`);
        const baseUrl = await fetchCountryInfo();
        const response = await fetch(`${baseUrl}/wp-json/lmscourses/v2/courses?id=${course_id}&course_category=${category_id}&course_tag=${tag_id}`);
        const jsonData = await response.json();
        let resData = [];
        fn(setRCourseTrandinglLoading(true))
        if (jsonData) {
            const data = jsonData.map((data) => {
                let img = null;
                // fetchImage(data?.featured_media).then((r) => {

                //     fetchCourseDetails(data?.id).then((detail) => {


                resData = [...resData,
                {
                    id: data?.ID,
                    categoryId: data?.categories[0]?.term_id,
                    categoryName: data?.categories[0]?.name,
                    categoryNames: data?.category_names,
                    description: data?.post_content,
                    name: data?.post_title,
                    slug: data?.post_name,
                    image: data?.images[0]?.guid,
                    images: data?.images,
                    level: data?.course_level,
                    duration: data?.course_duration,
                    video_count: data?.detail?.topics.length,
                    video: data?.video,
                    sale_price: data?.sale_price,
                    price: data?.price,
                    product_id: data?.product_id,
                    regular_price: data?.regular_price,
                    course_requirements: data?.course_requirements,
                    course_material_includes: data?.course_material_includes,
                    course_settings: data?.course_settings,
                    course_target_audience: data?.course_target_audience,
                    course_price_type: data?.course_price_type,
                    topics: data?.topics,
                    course_benefits: data?.course_benefits,
                    tags: data?.tags
                }
                ]
                fn(setRTrandingCourses(resData));

                //     });

                // });

            });
        }
        fn(setRCourseTrandinglLoading(false))
        //  }

    } catch (error) {
        console.error('Error:', error);
    }
};


export const getProducts = async (course_id = 0, category_id = 0, tag_id = 0, fn) => {
    try {
        //   if (id) {
        // const response = await fetch(`${baseUrl}/wp-json/wp/v2/courses?course-category=${id}`);
        // const response = await fetch(`${baseUrl}/wp-json/wc/v3/products?consumer_key=ck_764c80dfa5a5e48e54c934b2391442b2192b6ebc&consumer_secret=cs_4e61cd2ad57a84cd75d1f2a32c4c5b84e5b9708b`);
        const baseUrl = await fetchCountryInfo();
        const response = await fetch(`${baseUrl}/wp-json/lmscourses/v2/courses?id=${course_id}&course_category=${category_id}&course_tag=${tag_id}`);
        const jsonData = await response.json();
        let resData = [];
        fn(setRCourseLoading(true))
        if (jsonData?.length > 0) {
            const data = jsonData.map((data) => {
                let img = null;
                // fetchImage(data?.featured_media).then((r) => {

                //     fetchCourseDetails(data?.id).then((detail) => {


                resData = [...resData,
                {
                    id: data?.ID,
                    categoryId: data?.categories[0]?.term_id,
                    categoryName: data?.categories[0]?.name,
                    categoryNames: data?.category_names,
                    description: data?.post_content,
                    name: data?.post_title,
                    slug: data?.post_name,
                    image: data?.images[0]?.guid,
                    images: data?.images,
                    level: data?.course_level,
                    duration: data?.course_duration,
                    video_count: data?.detail?.topics.length,
                    video: data?.video,
                    sale_price: data?.sale_price,
                    price: data?.price,
                    product_id: data?.product_id,
                    regular_price: data?.regular_price,
                    course_requirements: data?.course_requirements,
                    course_material_includes: data?.course_material_includes,
                    course_settings: data?.course_settings,
                    course_target_audience: data?.course_target_audience,
                    course_price_type: data?.course_price_type,
                    topics: data?.topics,
                    course_benefits: data?.course_benefits,
                    tags: data?.tags
                }
                ]
                fn(setRCourses(resData));

            });
        } else {
            fn(setRCourses([]));
        }
        fn(setRCourseLoading(false))
        //  }

    } catch (error) {
        console.error('Error:', error);
    }
}


export const getFreeCourses = async (fn) => {
    try {

        const baseUrl = await fetchCountryInfo();
        const response = await fetch(`${baseUrl}/wp-json/lmscourses/v2/courses?id=0&course_category=0&course_tag=0`);
        const jsonData = await response.json();

        let resData = [];

        if (jsonData) {
            // console.log('jsonData', jsonData)
            jsonData.filter((data) => data.course_price_type == 'free').map(d => {
                resData = [...resData,
                {
                    id: d?.ID,
                    categoryId: d?.categories[0]?.term_id,
                    categoryName: d?.categories[0]?.name,
                    categoryNames: d?.category_names,
                    description: d?.post_content,
                    name: d?.post_title,
                    slug: d?.post_name,
                    level: d?.course_level,
                    tags: d?.tags
                }
                ]
                fn(setFreeCourses(resData));
            });
        }

    } catch (error) {
        console.error('Error:', error);
    }

};

export const getSearchProducts = async (fn) => {
    try {
        //   if (id) {
        // const response = await fetch(`${baseUrl}/wp-json/wp/v2/courses?course-category=${id}`);
        // const response = await fetch(`${baseUrl}/wp-json/wc/v3/products?consumer_key=ck_764c80dfa5a5e48e54c934b2391442b2192b6ebc&consumer_secret=cs_4e61cd2ad57a84cd75d1f2a32c4c5b84e5b9708b`);
        const baseUrl = await fetchCountryInfo();
        const response = await fetch(`${baseUrl}/wp-json/lmscourses/v2/courses?id=0&course_category=0&course_tag=0`);
        const jsonData = await response.json();

        let resData = [];

        if (jsonData) {
            const data = jsonData.map((data) => {
                let img = null;
                // fetchImage(data?.featured_media).then((r) => {

                //     fetchCourseDetails(data?.id).then((detail) => {


                resData = [...resData,
                {
                    id: data?.ID,
                    categoryId: data?.categories[0]?.term_id,
                    categoryName: data?.categories[0]?.name,
                    categoryNames: data?.category_names,
                    description: data?.post_content,
                    name: data?.post_title,
                    slug: data?.post_name,
                    level: data?.course_level,
                    tags: data?.tags
                }
                ]
                fn(setRSearchCourses(resData));

                //     });

                // });

            });
        }
        //  }

    } catch (error) {
        console.error('Error:', error);
    }
}



export const LoginUser = async (username, password, fn) => {
    try {
        const baseUrl = await fetchCountryInfo();
        const response = await fetch(`${baseUrl}/wp-json/user/v2/login?username=${username}&password=${password}`);
        const jsonData = await response.json();
        fn(setRLoginLoading(1))
        fn(setRLoginLoading(0))

        return jsonData;

    } catch (error) {
        console.error('Error:', error);
    }
}


export const coupons = async () => {
    try {
        const baseUrl = await fetchCountryInfo();
        const response = await fetch(`${baseUrl}/wp-json/wc/store/v1/cart/coupons`);
        const jsonData = await response.json();
        return jsonData;

    } catch (error) {
        console.error('Error:', error);
    }
}

export const addCouponToCart = async (item) => {
    const payload = {
        id: item.code,
        quantity: 1
    };
    const baseUrl = await fetchCountryInfo();
    const response = await fetch(`${baseUrl}/wp-json/wc/store/v1/cart/apply-coupon?code=${item.code}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    })
        .then(r => r.json())
        .catch((err) => console.log(err.message));

    return response;
}

export const AddToCart = async (item, fn) => {

    const formData = new FormData();
    formData.append('id', item.id || 2465);
    formData.append('quantity', 1);
    formData.append('user_id', JSON.parse(localStorage.getItem("persist:user")).userID);
    formData.append('cookies', JSON.stringify(item.cookies));
    const baseUrl = await fetchCountryInfo();
    const response = await fetch(`${baseUrl}/wp-json/lmscourses/v2/cart-add-item`,
        {
            method: 'POST', headers: {}, body: formData
        })
        .then(r => r.json())
        .catch((err) => console.log(err.message));
    return response;
}

function sleep(milliseconds) {
    return new Promise(resolve => {
        setTimeout(resolve, milliseconds);
    });
}

export const UpdateCartInfo = async (fn) => {

    const formData = new FormData();
    formData.append('user_id', JSON.parse(localStorage.getItem("persist:user")).userID);

    await sleep(10000); // Wait for 2 seconds
    // Retrieve data from localStorage
    const cartData = (localStorage.getItem("persist:cart"));

    //const parsedInfo = JSON.parse(cartData.cart); // Parse the 'cart' property
    console.log(cartData); // Verify if 'parsedInfo' contains the necessary information

    // Append the parsed 'cart' data to the FormData
    formData.append('cartInfo', cartData);

    try {
        const baseUrl = await fetchCountryInfo();
        const response = await fetch(`${baseUrl}/wp-json/lmscourses/v2/cart-update-item`,
            {
                method: 'POST', headers: {}, body: formData
            })
        const jsonData = await response.json();
        return false;
    } catch (err) {
        console.log(err.message)

    }
}

export const GetCartInfo = async (ID, isReload) => {
    const userID = ID || JSON.parse(localStorage.getItem("persist:user")).userID;
    const formData = new FormData();

    formData.append('user_id', userID);

    if (![undefined, null, 0, ''].includes(userID)) {
        const baseUrl = await fetchCountryInfo();
        const response = await fetch(`${baseUrl}/wp-json/lmscourses/v2/cart`,
            {
                method: 'POST', headers: {}, body: formData
            })
            .then(r => r.json())
            .catch((err) => console.log(err.message));


        // also updating the cart information
        //   let cartItemsData = await response.json();
        let localstorgaeItem = response?.cartInformation[0]?.user_cart_json_info;
        if (localstorgaeItem != 'undefined') {
            // set to loacal storage
            localStorage.setItem("persist:cart", localstorgaeItem);
        }
        isReload && location.reload();
        return response;
    } else {
        setTimeout(() => GetCartInfo(ID, isReload), 2000)
    }
}

export const ApplyCoupon = async (item, fn) => {

    //  JSON.stringify({
    //     cartItems: cart,
    //     userId: "123",
    // })
    // JSON.stringify({
    //     id: item.id,
    //     quantity: 1
    // })
    const formData = new FormData();
    formData.append('code', item.code);
    formData.append('cookies', JSON.stringify(item.cookies));

    const baseUrl = await fetchCountryInfo();
    const response = await fetch(`${baseUrl}/wp-json/lmscourses/v2/cart-add-coupon`,
        {
            method: 'POST', headers: {}, body: formData
        })
        .then(r => r.json())
        .catch((err) => console.log(err.message));

    return response;

}

export const RemoveCartCoupon = async (item, fn) => {

    //  JSON.stringify({
    //     cartItems: cart,
    //     userId: "123",
    // })
    // JSON.stringify({
    //     id: item.id,
    //     quantity: 1
    // })
    const formData = new FormData();
    formData.append('code', item.code);
    formData.append('cookies', JSON.stringify(item.cookies));

    const baseUrl = await fetchCountryInfo();
    const response = await fetch(`${baseUrl}/wp-json/lmscourses/v2/cart-remove-coupon`,
        {
            method: 'POST', headers: {}, body: formData
        })
        .then(r => r.json())
        .catch((err) => console.log(err.message));

    //   await UpdateCartInfo();
    return response;

}

export const RemoveCartItem = async (item, fn) => {

    const formData = new FormData();
    formData.append('key', item.key);
    formData.append('cookies', JSON.stringify(item.cookies));

    const baseUrl = await fetchCountryInfo();
    const response = await fetch(`${baseUrl}/wp-json/lmscourses/v2/cart-remove-item`,
        {
            method: 'POST', headers: {}, body: formData
        })
        .then(r => r.json())
        .catch((err) => console.log(err.message));
    return response;
}

export const getCart = async () => {

    try {
        //   if (id) {
        // const response = await fetch(`${baseUrl}/wp-json/wp/v2/courses?course-category=${id}`);
        // const response = await fetch(`${baseUrl}/wp-json/wc/v3/products?consumer_key=ck_764c80dfa5a5e48e54c934b2391442b2192b6ebc&consumer_secret=cs_4e61cd2ad57a84cd75d1f2a32c4c5b84e5b9708b`);
        // const response = await fetch(`${baseUrl}/wp-json/lmscourses/v2/courses?id=${course_id}&course_category=${category_id}&course_tag=${tag_id}`);
        const baseUrl = await fetchCountryInfo();
        const response = await fetch(`${baseUrl}/wp-json/wc/store/v1/cart`, {
            method: 'POST',
            body: JSON.stringify({
                user_id: JSON.parse(localStorage.getItem("persist:user")).userID
            })
        });
        const jsonData = await response.json();
        return jsonData;
    } catch (error) {
        console.error('Error:', error);
    }

}



export const Checkout = async (item, fn) => {
    const formData = new FormData();
    formData.append('payment_data', JSON.stringify(item.payment_data));
    formData.append('customer_id', item.customer_id);
    formData.append('create_account', item.create_account);
    formData.append('payment_method', item.payment_method);
    formData.append('customer_note', item.customer_note);
    formData.append('customer', JSON.stringify(item.customer));
    formData.append('cartItems', JSON.stringify(item.cartItems));
    formData.append('billing_address', JSON.stringify(item.billing_address));
    formData.append('cookies', JSON.stringify(item.cookies));
    formData.append('domain', item.domain);


    const baseUrl = await fetchCountryInfo();
    const response = await fetch(`${baseUrl}/wp-json/lmscourses/v2/checkout`,
        {
            method: 'POST', headers: {}, body: formData
        })
        .then(r => r.json())
        .catch((err) => console.log(err.message));

    return response;

}



export const CheckoutRazorPay = async (item, fn) => {

    const formData = new FormData();
    formData.append('payment_data', JSON.stringify(item.payment_data));
    formData.append('customer_id', item.customer_id);
    formData.append('create_account', item.create_account);
    formData.append('payment_method', item.payment_method);
    formData.append('customer_note', item.customer_note);
    formData.append('customer', JSON.stringify(item.customer));
    formData.append('cartItems', JSON.stringify(item.cartItems));
    formData.append('billing_address', JSON.stringify(item.billing_address));
    formData.append('cookies', JSON.stringify(item.cookies));
    formData.append('domain', item.domain);


    const baseUrl = await fetchCountryInfo();
    const response = await fetch(`${baseUrl}/wp-json/lmscourses/v2/checkout-razorpay`,
        {
            method: 'POST', headers: {}, body: formData
        })
        .then(r => r.json())
        .catch((err) => console.log(err.message));

    return response;

}


export const VerifyOrder = async (item, fn) => {

    //  JSON.stringify({
    //     cartItems: cart,
    //     userId: "123",
    // })
    // JSON.stringify({
    //     id: item.id,
    //     quantity: 1
    // })


    const formData = new FormData();
    formData.append('order_id', item.order_id);
    formData.append('payment_token', item.payment_token);
    formData.append('data', JSON.stringify(item.data));
    const baseUrl = await fetchCountryInfo();
    const response = await fetch(`${baseUrl}/wp-json/lmscourses/v2/payment`,
        {
            method: 'POST', headers: {}, body: formData
        })
        .then(r => r.json())
        .catch((err) => console.log(err.message));

    return response;

}

export const SignupUser = async (username, email, password, display_name, fn) => {
    try {
        //   if (id) {
        // const response = await fetch(`${baseUrl}/wp-json/wp/v2/courses?course-category=${id}`);
        // const response = await fetch(`${baseUrl}/wp-json/wc/v3/products?consumer_key=ck_764c80dfa5a5e48e54c934b2391442b2192b6ebc&consumer_secret=cs_4e61cd2ad57a84cd75d1f2a32c4c5b84e5b9708b`);
        // const response = await fetch(`${baseUrl}/wp-json/lmscourses/v2/courses?id=${course_id}&course_category=${category_id}&course_tag=${tag_id}`);
        const baseUrl = await fetchCountryInfo();
        const response = await fetch(`${baseUrl}/wp-json/user/v2/signup?username=${username}&password=${password}&email=${email}&display_name=${display_name}`);
        const jsonData = await response.json();

        return jsonData;

    } catch (error) {
        console.error('Error:', error);
    }
}

export const ContactUs = async (name, email, phone, message, fn) => {
    try {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('phone', phone);
        formData.append('message', message);

        const baseUrl = await fetchCountryInfo();
        const response = await fetch(`${baseUrl}/wp-json/contactus/v2/users`,
            {
                method: 'POST', headers: {}, body: formData
            })
            .then(r => r.json())
            .catch((err) => console.log(err.message));

        return response;


    } catch (error) {
        console.error('Error:', error);
    }



}

export const getCustomerOrders = async (user_id, fn) => {
    try {

        fn(setRUserOrderProducts({ payload: [] }));
        fn(setRUserOrders({ payload: [] }));
        //const response = await fetch(baseUrl+'/wp-json/wp/v2/course-category');
        const response = await fetch(`${baseUrl}/wp-json/user/v2/orders?user_id=${user_id}`);
        const jsonData = await response.json();
        // console.log(jsonData,'jsonData');
        if (jsonData) {
            fn(setRUserOrders({ payload: jsonData.data }));
            fn(setRUserOrderProducts({ payload: jsonData.products }));
        }

    } catch (error) {
        console.error('Error:', error);
    }
}

export const fetching_All_Blogs = async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const baseUrl = await fetchCountryInfo();
        const response = await fetch(`${baseUrl}/wp-json/lmscourses/v2/view-blog?ss=ss`);
        const jsonData = await response.json();
        if (jsonData?.blogs?.length > 0) {
            dispatch(getBlogs(jsonData?.blogs));
        }
        dispatch(setLoading(false));
        return jsonData;
    } catch (error) {
        dispatch(setLoading(false));
        console.error('Error:', error);
    }
}

export const fetching_blogs_by_category_id = async (dispatch, id) => {
    dispatch(setLoading(true));
    try {
        const baseUrl = await fetchCountryInfo();
        const response = await fetch(`${baseUrl}/wp-json/lmscourses/v2/blogs-by-categories?category_id=${id}`);
        const jsonData = await response.json();
        await dispatch(getBlogs(jsonData?.blogs));
        dispatch(setLoading(false));
        return jsonData;
    } catch (error) {
        dispatch(setLoading(false));
        console.error('Error:', error);
    }

}

export const fetching_Blog_By_ID = async (dispatch, id) => {
    dispatch(setLoading(true));
    try {
        const baseUrl = await fetchCountryInfo();
        const response = await fetch(`${baseUrl}/wp-json/lmscourses/v2/view-blog-details?id=${id}&time=${new Date().getTime()}`);
        const jsonData = await response.json();
        await dispatch(getBlogById(jsonData));
        dispatch(setLoading(false));
        return jsonData;
    } catch (error) {
        dispatch(setLoading(false));
        console.error('Error:', error);
    }
}
export const fetching_blog_categories = async (dispatch, id) => {
    dispatch(setLoading(true));
    try {
        const baseUrl = await fetchCountryInfo();
        const response = await fetch(`${baseUrl}/wp-json/lmscourses/v2/blog-categories-with-blogs`);
        const jsonData = await response.json();
        await dispatch(getBlogCategories(jsonData));
        dispatch(setLoading(false));
        return jsonData;
    } catch (error) {
        dispatch(setLoading(false));
        console.error('Error:', error);
    }
}